<template>
	<div>

		<!-- <tabs :tabs="tabs" v-model="tab" class="mb-10" /> -->

		<component :is="tab" />

	</div>
</template>

<script>
	export default {
		components: {
			personal: require('./Personal').default,
			corporate: require('./Corporate').default,
		},
		data() {
			return {
				tab: 'personal',
				tabs: [
					{ title: 'Personal', name: 'personal' },
					{ title: 'Corporate', name: 'corporate' },
				]
			}
		},
	}
</script>