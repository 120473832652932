<template>
    <div>

        
        <div class="grid grid-cols-2 mb-4">
            <div class="col-span-1">
                <h2 class="text-2xl font-bold">Corporate Card Requests</h2>
            </div>
            <div class="col-span-1">
                <div class="relative">
                    <input
                        type="search"
                        name="search"
                        class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
                        placeholder="Search for employee’s name, status, etc."
                        v-model="searchQuery">
                    <ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
                </div>
            </div>
        </div>

        <div class="border border-solid border-blue-200">
            <div class="flex">
                <div class="duration-300" :class="{'w-full': !isOpen, 'w-1/2': isOpen}">
                    <datatable
                        :index="true"
                        :reverse-index="true"
                        :data="requests.data"
                        :columns="requests.columns"
                        :query="searchQuery"
                        :className="tableRowClassName"
                        :loading="requests.loading"
                        ref="table"
                    />
                </div>
                <div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
                    <div class="h-full overflow-y-auto">
                        
                        <div class="animated animation-delay-300 fadeIn">
                            <div class="flex flex-row items-center justify-between mb-5">
                                <div class="flex flex-row items-baseline">
                                    <h2 class="text-lg font-bold mr-2">{{ request.user.name | sentenceCase }}</h2>
                                    <span class="font-hairline text-xs text-gray-500">{{ position | sentenceCase }}</span>
                                </div>
                                <div class="dropdown ml-auto">
                                    <button type="button" class="flex flex-col focus:outline-none" dropdown>
                                        <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                                        <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                                        <div class="h-1 w-1 bg-gray-400 rounded-full"></div>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <a href="#!" class="dropdown-menu-item" @click.prevent="approve">Approve</a>
                                        <a href="#!" class="dropdown-menu-item text-red-500" @click.prevent="decline">Decline</a>
                                        <!-- <a href="#!" class="dropdown-menu-item text-red-500" @click.prevent="confirmDelete">Delete</a> -->
                                    </ul>
                                </div>
                            </div>


                            <transition name="fade" mode="out-in">
                                <sm-loader v-if="approval.loading" />
                            </transition>

                            <div class="w-100 p-4 border border-blue-200 rounded text-xs" v-if="bankStatement">
                                <div class="flex flex-row mb-3">
                                    <div class="font-medium">Bank Statement</div>
                                    <div class="ml-auto">Uploaded: {{ bankStatement.created_at }}</div>
                                </div>
                                <a :href="bankStatement.url" target="_blank" class="btn btn-blue btn-sm">View Bank Statement</a>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <modal className="w-full md:w-2/5 xl:w-3/10" ref="confirmApprovalModal">

            <template v-if="approval && approval.action === 'approve'">

                <form @submit.prevent="approve">
                    
                    <div class="mb-10">
                        <div class="text-xl font-bold">
                            Approve this card request?
                        </div>
                    </div>

                    <template v-if="getFormError(approval)">
                        <div class="alert alert-red-soft mb-10">
                            <span class="alert-icon mr-3">!</span>
                            <span>{{ getFormError(approval) }}</span>
                        </div>
                    </template>

                    <form-group
                        type="number"
                        name="interest"
                        :form="approval"
                        v-model="approval.data.interest.value"
                        left-icon="cellular-outline"
                    >
                        Interest Rate
                    </form-group>

                    <form-group
                        type="money"
                        name="credit_limit"
                        :form="approval"
                        v-model="approval.data.credit_limit.value"
                        left-icon="wallet-outline"
                    >
                        Credit Limit
                    </form-group>

                    <form-group
                        type="money"
                        name="fee"
                        :form="approval"
                        v-model="approval.data.fee.value"
                        left-icon="card-outline"
                    >
                        Fee
                    </form-group>

                    <div class="flex items-center justify-center">

                        <button
                            type="button"
                            class="btn btn-orange-soft"
                            @click.prevent="$refs.confirmApprovalModal.close"
                            :disabled="approval.loading"
                        >
                            Cancel
                        </button>
                        
                        <button
                            type="submit"
                            class="btn btn-blue ml-3"
                            :disabled="approval.loading"
                        >
                            <span v-if="approval.loading">Loading...</span>
                            <span v-else>Proceed</span>
                        </button>
                    </div>
                    
                </form>

            </template>
            <template v-else-if="approval && approval.action === 'decline'">

                <div class="text-center">

                    <div class="mb-10">
                        <div class="text-xl font-bold mb-10">
                            Delete This User?
                        </div>

                        <div class="font-light text-gray-500 text-sm">
                            <p class="mb-2">This user will be permanently removed from the platform.</p>
                        </div>
                    </div>
                    
                </div>

                <template v-if="getFormError(approval)">
                    <div class="alert alert-red-soft mb-10">
                        <span class="alert-icon mr-3">!</span>
                        <span>{{ getFormError(approval) }}</span>
                    </div>
                </template>

                <div class="flex items-center justify-center">

                    <button
                        type="button"
                        class="btn btn-blue-soft"
                        @click.prevent="$refs.confirmApprovalModal.close"
                        :disabled="approval.loading"
                    >
                        Cancel
                    </button>
                    

                    <button
                        type="button"
                        class="btn btn-red ml-3"
                        @click.prevent="decline"
                        :disabled="approval.loading"
                    >
                        <span v-if="approval.loading">Loading...</span>
                        <span v-else>Proceed</span>
                    </button>
                </div>

            </template>
            <template v-else></template>

        </modal>

        <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="approvalSuccessModal">

            <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

            <div class="text-lg font-bold mb-4">
                Card Request Approved.
            </div>
            <div class="text-xs mb-10">
                The user will be notified when his card and pin are ready.
            </div>

            <button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="$refs.approvalSuccessModal.close">
                Okay
            </button>
            
        </modal>

        <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="declineSuccessModal">

            <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

            <div class="text-lg font-bold mb-4">
                Card Request Declined!
            </div>
            <!-- <div class="text-xs mb-10">
                The user
            </div> -->

            <button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="$refs.declineSuccessModal.close">
                Okay
            </button>
            
        </modal>

    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                request: null,
                requests: this.$options.resource([], {
                    columns: [
                        {
                            name: 'name',
                            th: 'Staff Name',
                            render: (request) => `${request.user?.name} ${request.user?.last_name}`
                        },
                        {
                            name: 'company',
                            th: 'Company',
                            render: (request) => request.user.company?.name
                        },
                        {
                            name: 'email',
                            th: 'Email Address',
                            render: (request) => request.user.email
                        },
                        {
                            name: 'phone',
                            th: 'phone',
                            render: (request) => request.user.phone_no
                        },
                        {
                            name: 'status',
                            th: 'status',
                            render: (request, status) => {
                                switch (status) {
                                    case 'approved':
                                        return '<div class="badge badge-green-soft-outline">Approved</div>';
                                    case 'declined':
                                        return '<div class="badge badge-green-soft-outline">Declined</div>';
                                    case 'pending':
                                    default:
                                        return '<div class="badge badge-blue-soft-outline">Pending</div>';
                                }
                            }
                        },
                        {
                            className: 'w-1/10',
                            name: 'acction',
                            th: '',
                            render: (request) => {
                                return `
                                    <div class="flex items-center">
                                        <a
                                            href="#!"
                                            class="btn btn-blue btn-sm mr-5"
                                            data-click="openBankStatement(${request.user.bank_statements[request.user.bank_statements.length - 1].url})"
                                        >
                                            View Bank Statement
                                        </a>
                                        ${
                                            request.status == 'pending' ? `
                                                <div class="dropdown ml-auto inline-block">
                                                    <button type="button" class="flex flex-col focus:outline-none pl-3" dropdown>
                                                        <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                                                        <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                                                        <div class="h-1 w-1 bg-gray-400 rounded-full"></div>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <a href="#!" class="dropdown-menu-item" data-click="confirm(${request.id}, approve)">Approve</a>
                                                        <a href="#!" class="dropdown-menu-item text-red-500" data-click="confirm(${request.id}, decline)">Decline</a>
                                                    </ul>
                                                </div>
                                            `
                                            : ``
                                        }
                                    </div>
                                `;
                            }
                        }
                    ],
                }),
                approval: this.$options.basicForm([
                    'interest',
                    'credit_limit',
                    'fee',
                ], { action: null }),
                checkmark: require('@/assets/checkmark-base.svg'),
                loading: null,
                searchQuery: '',
            }
        },
        computed: {
            ...mapState(['banks']),
            
            bankStatement() {
                return this.request?.user?.bank_statements?.[this.request?.user?.bank_statements.length - 1];
            },
            company_id() {
                return this.user.company_id;
            },
            company_name() {
                return this.$route.query.company_name;
            },
            isOpen() {
                // return !!this.request
                return false;
            },
            position() {
                return this.request?.user?.profile?.position;
            },
            totalLoanAmount() {
                return this.transactions && this.transactions.reduce( (last, transaction) => last + Number(transaction.loan_amount), 0 );
            },
            transactions() {
                return this.request?.user?.loan;
            },
        },
        beforeMount() {
            this.getRequests();
        },
        mounted() {
            this.$options.listenForClick.apply(this);
        },
        methods: {
            click(request) {
                this.request = this.request === request ? null : request;
                this.$refs.table.renderData();
            },
            tableRowClassName(row) {
                return row.id === this.request?.user?.id ? 'selected' : '';
            },
            openBankStatement(url) {
                window.open(url);
            },
            confirm(request, action) {
                this.request = this.requests.data.find(r => r.id == request);
                this.approval.action = action;
                this.$refs.confirmApprovalModal.open();

                this.approval.data.fee.value

            },
            async getRequests() {
                this.requests.loading = true;
                await this.$get({
                    url: `${this.$baseurl}/companies/card/requests/all`,
                    headers: this.headers,
                    success: response => {
                        this.requests.data = response.data.card_requests;
                        if (this.request) {
                            this.request = this.requests.data.find( request => request.id === this.request.id );
                        }
                    }
                });
                this.requests.loading = false;
            },
            async approve() {
                const request = this.request;
                if (!request) {
                    console.log('No Request Selected')
                    return false;
                }

                var fee_type;

                switch (request.personal_card_plans_id) {
                    case 1:
                        fee_type = 'monthly_fees';
                        break;
                    case 2:
                        fee_type = 'quartely_fees';
                        break;
                    case 3:
                        fee_type = 'yearly_fees';
                        break;
                    default:
                        // statements_def
                        break;
                }

                this.approval.error = false;
                this.approval.loading = true;
                await this.$post({
                    url: `${this.$baseurl}/personal/card/approve`,
                    headers: this.headers,
                    data: {
                        userId: request.user.id,
                        interest: this.approval.data.interest.value,
                        credit_limit: this.approval.data.credit_limit.value,
                        [fee_type]: this.approval.data.fee.value
                    },
                    success: () => {
                        this.getRequests();
                        this.$refs.confirmApprovalModal.close();
                        this.$refs.approvalSuccessModal.open();
                    },
                    error: error => {
                        this.approval.error = error;
                    }
                });
                this.approval.loading = false;
            },
            async decline() {
                const request = this.request;
                if (!request) {
                    console.log('No Request Selected')
                    return false;
                }

                this.approval.error = false;
                this.approval.loading = true;
                await this.$post({
                    url: `${this.$baseurl}/personal/card/decline`,
                    headers: this.headers,
                    data: {
                        userId: request.user.id
                    },
                    success: () => {
                        this.getRequests();
                        this.$refs.confirmApprovalModal.close();
                        this.$refs.declineSuccessModal.open();
                    },
                    error: error => {
                        this.approval.error = error;
                    }
                });
                this.approval.loading = false;
            },
        }
    }
</script>