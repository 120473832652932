<template>
  <div>
    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <h2 class="text-2xl font-bold">Personal Card Requests</h2>
      </div>
      <div class="col-span-1">
        <div class="relative">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
            placeholder="Search for employee’s name, status, etc."
            v-model="searchQuery"
            @keyup.enter="$refs.table.loadAjaxData"
          />
          <ion-icon
            name="search-outline"
            class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
            style="top: 50%; transform: translateY(-50%);"
          ></ion-icon>
        </div>
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :reverse-index="true"
        :url="`${this.$baseurl}/personal/cards/all`"
        :ajax="true"
        :exportable="true"
        :fillable="true"
        :ajaxPagination="true"
        :data="requests.data"
        :columns="requests.columns"
        :query="searchQuery"
        :onClick="click"
        ref="table"
      />
    </div>

    <modal className="w-full md:w-3/5 xl:w-4/10" ref="modal">
      <form @submit.prevent="approve">
        <h1 class="text-2xl font-bold mb-10">
          Review this personal card request
        </h1>

        <div v-if="request">
          <div class="mb-10">
            <div
              class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200"
            >
              <div class="col-span-1 py-4">
                <div class="opacity-75 text-sm font-medium mb-1">Name</div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon
                    name="person-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  {{ request.user && request.user.name }}
                </div>
              </div>
              <div
                class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
              >
                <div class="opacity-75 text-sm font-medium mb-1">Company</div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon
                    name="person-circle-outline"
                    class="text-lg mr-2 text-blue-500"
                  ></ion-icon>
                  <router-link
                    :to="{
                      name: 'staff-view',
                      params: { staffId: request.user.id },
                    }"
                    target="_blank"
                    class="text-blue-500 text-sm"
                  >
                    View Profile
                  </router-link>
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200"
            >
              <div class="col-span-1 py-4">
                <div class="opacity-75 text-sm font-medium mb-1">Company</div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon
                    name="business-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  {{
                    request.user && request.user.company
                      ? request.user.company.name
                      : "---"
                  }}
                </div>
              </div>
              <div
                class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
              >
                <div class="opacity-75 text-sm font-medium mb-1">
                  Credit Limit
                </div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon name="card-outline" class="text-lg mr-2"></ion-icon>
                  {{
                    request.user && request.user.profile.credit_limit | currency
                  }}
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200"
            >
              <div class="col-span-1 py-4">
                <div class="opacity-75 text-sm font-medium mb-1">
                  Subscription
                </div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon
                    name="options-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  {{ request.personal_card_plan.plan_name }}
                </div>
              </div>
              <div
                class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
              >
                <div class="opacity-75 text-sm font-medium mb-1">
                  Subscription Fee
                </div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                  {{ request.personal_card_plan.default_fee | currency }}
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200"
            >
              <div class="col-span-1 py-4">
                <div class="opacity-75 text-sm font-medium mb-1">
                  Billing Cycle
                </div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon
                    name="calendar-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  {{ "annually" }}
                </div>
              </div>
              <div
                class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
              >
                <div class="opacity-75 text-sm font-medium mb-1">
                  Interest Rate
                </div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon
                    name="bar-chart-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  {{ request.personal_card_plan.interest }}%
                </div>
              </div>
            </div>
          </div>

          <template v-if="getFormError(approval)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon mr-3">!</span>
              <span>{{ getFormError(approval) }}</span>
            </div>
          </template>

          <div class="flex items-center justify-center flex-wrap">
            <button
              type="button"
              class="btn btn-orange-soft"
              @click.prevent="$refs.modal.close"
              :disabled="approval.loading"
            >
              Not Yet
            </button>

            <button
              type="submit"
              class="btn btn-blue ml-3"
              :disabled="approval.loading"
            >
              <span v-if="approval.loading">Loading...</span>
              <span v-else>Approve</span>
            </button>
          </div>
        </div>
      </form>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="approvalSuccessModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Card Request Approved.
      </div>
      <div class="text-sm mb-10">
        The user will be notified when his card and pin are ready.
      </div>

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.approvalSuccessModal.close"
      >
        Okay
      </button>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="declineSuccessModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Card Request Declined!
      </div>
      <!-- <div class="text-xs mb-10">
        The user
      </div> -->

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.declineSuccessModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      request: null,
      requests: this.$options.resource([], {
        columns: [
          {
            name: "name",
            th: "Staff Name",
            render: (request) => {
              if (!request.user) {
                return "N/A";
              }
              return `${request.user.name} ${request.user.last_name}`;
            },
          },
          {
            name: "company",
            th: "Company",
            render: (request) => request.user?.company?.name,
          },
          {
            name: "email",
            th: "Email Address",
            render: (request) => request.user?.email,
          },
          {
            name: "phone",
            th: "phone",
            render: (request) => request.user?.phone_no,
          },
          {
            name: "status",
            th: "status",
            render: (request, status) => {
              switch (status) {
                case "approved":
                  return '<div class="badge badge-green-soft-outline">Approved</div>';
                case "declined":
                  return '<div class="badge badge-red-soft-outline">Declined</div>';
                case "pending":
                default:
                  return '<div class="badge badge-gray-soft-outline">Pending</div>';
              }
            },
          },
          {
            className: "w-1/10",
            name: "acction",
            th: "",
            render: (request) => {
              return (
                request.user &&
                `
                  <div class="flex items-center">
                    <a
                      href="#!"
                      class="btn btn-blue btn-sm mr-5"
                      data-click="viewDocument(${
                        request.user?.bank_statements?.[
                          request.user?.bank_statements?.length - 1
                        ]?.id
                      })"
                    >
                      View Bank Statement
                    </a>
                  </div>
                `
              );
            },
          },
        ],
      }),
      approval: this.$options.basicForm([], { action: null }),
      loading: null,
      searchQuery: "",
    };
  },
  beforeMount() {
    //this.getRequests();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(request) {
      this.request = request;
      this.$refs.modal.open();
    },
    openBankStatement(url) {
      window.open(url);
    },
    async getRequests() {
      this.requests.loading = true;
      await this.sendRequest("admin.cards.personal.requests", {
        success: (response) => {
          this.requests.data = response.data.card_requests;
          if (this.request) {
            this.request = this.requests.data.find(
              (request) => request.id === this.request.id
            );
          }
        },
        error: (error) => {
          this.requests.error = error;
        },
      });
      this.requests.loading = false;
    },
    async approve() {
      const request = this.request;
      if (!request) {
        console.log("No Request Selected");
        return false;
      }

      this.approval.error = false;
      this.approval.loading = true;
      await this.sendRequest("admin.cards.personal.approve", {
        data: {
          userId: request.user.id,
        },
        success: () => {
          //this.getRequests();
          this.$refs.table.loadAjaxData()
          this.$refs.modal.close();
          this.$refs.approvalSuccessModal.open();
        },
        error: (error) => {
          this.approval.error = error;
        },
      });
      this.approval.loading = false;
    },
    async decline() {
      const request = this.request;
      if (!request) {
        console.log("No Request Selected");
        return false;
      }

      this.approval.error = false;
      this.approval.loading = true;
      await this.sendRequest("admin.cards.personal.decline", {
        data: {
          userId: request.user.id,
        },
        success: () => {
          //this.getRequests();
          this.$refs.table.loadAjaxData()
          this.$refs.modal.close();
          this.$refs.declineSuccessModal.open();
        },
        error: (error) => {
          this.approval.error = error;
        },
      });
      this.approval.loading = false;
    },
    async viewDocument(documentId) {
			await this.$get({
				url: `${this.$baseurl}/staff/documents/${documentId}`,
				headers: {
					...this.headers,
					"Content-Type": "application/json",
				},
				success: (response) => {
					window.open(
						response.data.url,
						'_blank' // <- This is what makes it open in a new window.
					);
				},
				error: () => {
          
				},
			});
			this.loading = false;
		},
  },
};
</script>